<script setup>
import { Notyf } from "notyf";
import { ref, onMounted } from "vue";
import { useSaleStore } from "@/store/sales";
import { t } from "@/utils/i18n";

const notyf = new Notyf();
const saleStore = useSaleStore();

const loadCarriersTotal = async () => {
  try {
    const carriersTotal = await saleStore.fetchCarriersTotal();
    series.value[0].data = Object.values(carriersTotal);
  } catch (error) {
    notyf.error("Error loading data for the Service Plans");
  }
};

//number of plan sales
const series = ref([
  {
    data: [],
  },
]);

//chart configuration
const chartOptions = ref({
  chart: {
    type: "bar",
    height: 350,
    fontFamily: "Inter, sans-serif",
    fontSize: "14px",
  },
  colors: ["#895E8B"],
  plotOptions: {
    bar: {
      borderRadius: 4,
      borderRadiusApplication: "end",
      horizontal: true,
    },
  },
  dataLabels: {
    enabled: true,
  },
  xaxis: {
    categories: [
      "Aetna",
      "Ambetter",
      "Banner Aetna",
      "Blue Cross Blue",
      "Christus Health Plan",
      "Cigna",
      "Molina",
      "Oscar",
      "United Health Care (UHC)",
      "",
      "",
    ],
    labels: {
      align: "right", // Align labels to the right
      offsetX: -10, // Adjust the offset as needed
    },
  },
  tooltip: {
    enabled: false,
  },
});

onMounted(loadCarriersTotal);
</script>

<template>
  <div class="card flex-fill">
    <div class="card-header border-0">
      <div class="d-flex justify-content-between align-items-center">
        <h5 class="card-title">{{ t("dashboard.text3") }}</h5>
      </div>
    </div>
    <div class="card-body pt-0">
      <div class="border-dotted mb-3"></div>
      <div id="chart">
        <apexchart
          type="bar"
          height="350"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </div>
    </div>
  </div>
</template>
