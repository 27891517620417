<template>
  <!-- Modal after submit with approve -->
  <div
    class="modal fade"
    id="approveMosal"
    tabindex="-1"
    aria-labelledby="approveModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body text-center">
          <img
            src="@/assets/img/approve.png"
            alt="Approve Icon"
            class="img-fluid mb-4"
          />

          <p class="mb-4">
            <span style="color: #57015f">
              Sale number
              <strong style="color: #57015f"> {{ salesData.saleId }} </strong>
              <span style="color: #99a1a8">
                has been submitted succesfully.</span
              >
            </span>
          </p>
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            @click="updateApproveDecline(true)"
          >
            {{ t("sales.details.buttons.continue") }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal after confirm decline -->
  <div
    class="modal fade"
    id="confirmationModal"
    tabindex="-1"
    aria-labelledby="confirmationModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body text-center">
          <img
            src="@/assets/img/cancel-img.png"
            alt="Declined Icon"
            class="img-fluid mb-4"
          />

          <p class="mb-4">
            <span style="color: #57015f">
              Sale number
              <strong style="color: #57015f"> {{ salesData.saleId }} </strong>
              <span style="color: #99a1a8"> has been declined to seller.</span>
            </span>
          </p>

          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            @click="updateApproveDecline(false, reason, selectedFile)"
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal decline -->
  <div
    class="modal fade"
    id="declineModal"
    tabindex="-1"
    aria-labelledby="declineModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header border-0">
          <h5 class="modal-title" style="color: #57015f">
            {{ t("sales.modal.declined.declined") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="mb-3">
            <div class="d-flex justify-content-between align-items-center mb-2">
              <label for="reason" class="form-label mb-0">
                {{ t("sales.modal.declined.comment") }}</label
              >
              <div class="position-relative" style="width: 120px">
                <button
                  type="button"
                  class="btn btn-outline-primary2 flex-fill rounded-1 d-flex align-items-center justify-content-between"
                  @click="triggerFileInput"
                  style="font-size: 0.875rem; padding: 0.25rem 0.5rem"
                >
                  <span v-if="!selectedFile" class="w-100 text-center">{{
                    t("sales.modal.declined.img")
                  }}</span>
                  <template v-else>
                    <span class="text-truncate" style="max-width: 85px">
                      {{ selectedFile.name }}
                    </span>
                    <button
                      type="button"
                      class="btn-close-small"
                      @click.stop="clearFile"
                      aria-label="Clear file"
                    >
                      ×
                    </button>
                  </template>
                </button>
                <input
                  type="file"
                  ref="fileInput"
                  @change="handleFileUpload"
                  class="d-none"
                  accept="image/*"
                />
              </div>
            </div>
            <textarea
              v-model="reason"
              class="form-control rounded-1"
              id="reason"
              rows="3"
              :placeholder="t('sales.modal.declined.txt')"
            ></textarea>
          </div>
        </div>
        <div class="p-3 mt-3">
          <button
            type="button"
            class="btn btn-primary2 flex-fill rounded"
            style="
              border-radius: 8px;
              padding: 8px 24px;
              position: absolute;
              bottom: 24px;
              right: 24px;
              font-size: 14px;
            "
            data-bs-toggle="modal"
            data-bs-target="#confirmationModal"
          >
            {{ t("sales.details.buttons.declined") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useSaleStore } from "@/store/sales";
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { Notyf } from "notyf";
import { t } from "@/utils/i18n";

const saleStore = useSaleStore();
const salesData = ref({});
const route = useRoute();
const router = useRouter();

const notyf = new Notyf();
const uid = Object.keys(route.query)[0];
const isLoading = ref(true);
const userInfo = localStorage.getItem("user");
const userRole = JSON.parse(userInfo).role;

const selectedFile = ref(null);
const fileInput = ref(null);
const reason = ref("");

const saleById = async () => {
  try {
    const sales = await saleStore.fetchSaleById(uid);
    salesData.value = sales;
  } catch (error) {
    notyf.error("Error loading users");
  } finally {
    isLoading.value = false;
  }
};

const updateApproveDecline = async (isApproved) => {
  try {
    let newRole = userRole == "SUPERVISOR" ? "PROCESSOR" : userRole;
    await saleStore.updateApproveDecline(uid, {
      reason: reason.value,
      file: selectedFile.value,
      salesId: salesData.value.saleId,
      recjetedBy: newRole,
      isAccepted: isApproved,
      role: userRole,
    });

    if (isApproved) {
      notyf.success("Venta aprobada exitosamente");
    } else {
      notyf.success("Venta rechazada con éxito");
    }

    router.push(`/sales/sale-list`);
  } catch (error) {
    if (isApproved) {
      notyf.error("No se pudo aprobar la venta.");
    } else {
      notyf.error("No se pudo rechazar la venta.");
    }
  }
};

const clearFile = () => {
  selectedFile.value = null;
  if (fileInput.value) {
    fileInput.value.value = "";
  }
};

const triggerFileInput = () => {
  fileInput.value.click();
};

const isValidFileType = (file) => {
  const acceptedTypes = ["image/jpeg", "image/png"];
  return acceptedTypes.includes(file.type);
};

const handleFileUpload = (event) => {
  const file = event.target.files[0];
  if (file) {
    if (isValidFileType(file)) {
      selectedFile.value = file;
      const fileUrl = URL.createObjectURL(file);
      console.log("File URL:", fileUrl);
    } else {
      notyf.error("Invalid file. Please upload only JPG or PNG images.");
      clearFile();
    }
  }
};

onMounted(saleById);
</script>

<style scoped>
.btn-close-small {
  padding: 0;
  background: none;
  border: none;
  font-size: 1.2rem;
  line-height: 1;
  color: #666;
  cursor: pointer;
  margin-left: 4px;
}

.btn-close-small:hover {
  color: #000;
}

.btn-outline-primary2 {
  width: 120px;
  white-space: nowrap;
  overflow: hidden;
}
</style>
