import { defineStore } from "pinia";
import { ref } from "vue";
import axios from "@/services/axiosService";

export const useAuthStore = defineStore("login", () => {
  const email = ref("");
  const uid = ref("");
  const password = ref("");
  const isLoading = ref(false);
  const error = ref(null);
  const user = ref(null);

  const authenticate = (credentials) => {
    email.value = credentials.email;
    password.value = credentials.password;
  };

  const login = async () => {
    isLoading.value = true;
    error.value = null;
    try {
      const response = await axios.post("/auth/login", {
        email: email.value,
        password: password.value,
      });
      user.value = response;
      if (response.data.token) {
        const userInfo = {
          uid: response.data.user.uid,
          role: response.data.user.role,
          email: response.data.user.email,
          name: response.data.user.name,
          profileUrl: response.data.user.profileUrl,
          company: response.data.user.company,
        };
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("refreshToken", response.data.refreshToken);
        localStorage.setItem("user", JSON.stringify(userInfo));

        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${response.data.token}`;
      }
    } catch (err) {
      if (err.response) {
        // El servidor respondió con un estado fuera del rango de 2xx
        error.value = err.response.data.message || "An error occurred";
      } else if (err.request) {
        // La solicitud fue hecha pero no se recibió respuesta
        error.value = "No response from server";
      } else {
        // Algo sucedió en la configuración de la solicitud que provocó un error
        error.value = "Error setting up the request";
      }
    } finally {
      isLoading.value = false;
    }
  };

  const changesPassword = async (uid, password) => {
    try {
      const token = localStorage.getItem("token");
      await axios.post(
        "/users/change-password",
        {
          uid: uid,
          password: password,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      console.log(error);
      if (error.response) {
        ("An error occurred");
      }
    }
  };

  const logout = () => {
    user.value = null;
    email.value = null;
    password.value = null;
    localStorage.removeItem("token");
    delete axios.defaults.headers.common["Authorization"];
  };

  return {
    email,
    password,
    isLoading,
    error,
    user,
    authenticate,
    login,
    logout,
    changesPassword,
  };
});
